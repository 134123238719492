<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <!-- <b-button variant="outline-info" v-b-modal.modal-add>
                <b-icon icon="plus-circle-fill" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Register Product
            </b-button> -->
        <router-link class="btn btn-outline-info" to="purchase-order" exact>
            <span class="fa fa-arrow-left mr-2"></span> Back to Purchase Order List
        </router-link>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Create Purchase Order</h4>
                    </div>
                    <!-- <div class="card-action card-tabs mt-3 mt-sm-0">

                    </div> -->
                </div>
                <div class="card-body">
                    <form ref="formAdd" @submit.prevent="handleSubmit">
                        <div class="form-row">
                            <div class="form-group col-md-6 col-lg-2">
                                <label class="text text-info">No Purchase Request</label>
                                <v-select label="no_pr" :filterable="false" v-model="selected.purchase_request_name" :options="purchase_requests" @search="onSearchPurchaseRequest" @input="setSelectedPurchaseRequest">
                                    <template slot="no-options"> type to search purchase request... </template>
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            {{ option.no_pr }}
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ option.no_pr }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                            <div class="form-group col-md-6 col-lg-2">
                                <label class="text text-info">No Purchase Order</label>
                                <input type="text" class="form-control" v-model="formInput.no_po" readonly>
                            </div>
                            <div class="form-group col-md-6 col-lg-2">
                                <label class="text text-info">Date PO</label>
                                <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.date_po">
                                </b-form-datepicker>
                            </div>
                            <div class="form-group col-md-6 col-lg-2">
                                <label class="text text-info">Due Date</label>
                                <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.due_date" :min="formInput.date_po">
                                </b-form-datepicker>
                            </div>
                            <div class="form-group col-md-6 col-lg-2">
                                <label class="text text-info">Discount</label>
                                <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" v-model.number="formInput.discount" />
                            </div>
                            <div class="form-group col-md-3 col-lg-2">
                                <label class="text text-info">Currency</label>
                                <v-select :options="currency" v-model="formInput.currency"></v-select>
                            </div>
                            <div class="form-group col-md-3 col-lg-2" v-if="formInput.currency !== 'IDR'">
                                <label class="text text-info">Rate</label>
                                <input type="numeric" class="form-control" v-model="formInput.rate">
                            </div>
                            <div class="form-group col-md-3 col-lg-2">
                                <label class="text text-info">Down payment</label>
                                <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" v-model.number="formInput.down_payment" />
                            </div>
                        </div>
                        <div class="form-row mt-5" v-if="principles.length > 0">
                            <table class="table table-bordered" v-for="(val, index) in principles" :key="index">
                                <thead class="thead-light">
                                    <tr>
                                        <th colspan="3" width="50%">{{ val.name }}</th>
                                        <th colspan="3" class="text-right" width="50%">
                                            <div class="form-inline pull-right">
                                                <div class="custom-control custom-checkbox">
                                                    <input readonly type="checkbox" class="custom-control-input" v-model="val.include_tax" :true-value="1" :false-value="0" :id="'includeTax'+index">
                                                    <label class="custom-control-label" :for="'includeTax'+index"> Include Tax </label>
                                                </div>
                                                <button type="button" class="btn btn-info btn-sm ml-3" @click="createPO(val)">
                                                    <i class="fa fa-save"></i>&nbsp; CREATE PO
                                                </button>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-dark text-white">
                                        <td width="11%">Part Number</td>
                                        <td width="30%">Product Name</td>
                                        <td width="10%">Qty</td>
                                        <td width="16%">Price</td>
                                        <td width="16%">Discount</td>
                                        <td width="17%" class="text-right">Total</td>
                                    </tr>
                                    <tr v-for="(product, i) in val.arr" :key="'product_' + i">
                                        <td>{{ product.part_number }}</td>
                                        <td>{{ product.name }}</td>
                                        <td>
                                            <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" v-model.number="product.qty" />
                                        </td>
                                        <td>
                                            <currency-input type="text" currencyDisplay="hidden" :valueAsInteger="false" :precision="1" :currency="formInput.currency" locale="en" :allowNegative="false" :distractionFree="false" class="form-control" v-model.number="product.price" />
                                        </td>
                                        <td>
                                            <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" v-model.number="product.discount" />
                                        </td>
                                        <td class="text-right">{{ ((product.qty * product.price) - product.discount).toLocaleString('id-ID') }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
                <!-- <div class="card-footer d-sm-flex justify-content-between align-items-center" v-if="products.length > 0">
                    <div class="card-footer-link mb-4 mb-sm-0">
                        <p class="card-text text-dark d-inline">Total Item: {{ products.length || 0 }} <br> Total Selected Item: {{ selected.length || 0 }} </p>
                    </div>

                    <button @click="handleSubmit" class="btn btn-outline-success" :disabled="selected.length == 0"><i class="fa fa-save mr-1"></i> {{ selected.length == 0 ? 'PLEASE SELECT PRODUCT' : 'SAVE TO STOCK' }}</button>
                </div> -->
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";
const moment = require("moment");

export default {
    name: "CreatePurchaseOrder",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Create Purchase Order | %s',
    },
    data() {
        return {
            purchase_requests: [],
            currency: ['IDR', 'USD', 'EUR', 'JPY', 'CNY', 'KWD', 'BHD', 'OMR', 'JOD', 'GBP', 'KYD', 'CHF', 'CAD', 'AUD', 'LYD', 'AZN'],
            principles: [],
            selected: {
                purchase_request_name: '',
                principle_name: ''
            },
            formInput: {
                id_purchase_request: '',
                id_principle: '',
                include_tax: 0,
                no_po: '',
                date_po: '',
                due_date: '',
                discount: 0,
                currency: 'IDR',
                rate: 1,
                down_payment: 0,
                products: [],
            },
        };
    },
    mounted() {
        this.requestNumberPO();
    },
    methods: {
        async requestNumberPO() {
            try {
                const params = new URLSearchParams();
                params.append('type', 'PO');
                params.append('back_date', 0);
                params.append('date', moment().format('YYYY-MM-DD'));

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.get(
                    "dental-erp-service/generate/number", {
                        params: params,
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.formInput.no_po = resp.data.data.number;
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Number SO", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Number SO", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        // filterProduct(data) {
        //     let arr = [];

        //     data.forEach(function (value, index) {
        //         if (index == 0) {
        //             arr.push({
        //                 id_product: value.id_product,
        //                 part_number: value.part_number,
        //                 name: value.name,
        //                 price: 0,
        //                 discount: 0,
        //                 qty: 1,
        //             });
        //         } else {
        //             let found = false;

        //             for (let i = 0; i < arr.length; i++) {
        //                 const element = arr[i];
        //                 if (element.part_number == value.part_number) {
        //                     found = true;
        //                     arr[i].qty += 1;
        //                     break;
        //                 }
        //             }

        //             if (found == false) {
        //                 arr.push({
        //                     id_product: value.id_product,
        //                     part_number: value.part_number,
        //                     name: value.name,
        //                     price: 0,
        //                     discount: 0,
        //                     qty: 1,
        //                 });
        //             }
        //         }
        //     });
        //     return arr;
        // },
        async onSearchPurchaseRequest(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/purchase_request/purchasing", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.purchase_requests = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request RFQ", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request RFQ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedPurchaseRequest(value) {

            if (value == null) {
                this.principles = [];
                this.formInput.id_purchase_request = '';
                this.formInput.include_tax = 0;
                return;
            }

            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/purchase_request/purchasing/find_by_principle", {
                        id: value.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    let shelter = [];

                    resp.data.data.forEach(function (value) {

                        let arr = [];

                        value.show_products.forEach(function (val, idx) {

                            if (idx == 0) {
                                arr.push({
                                    id_product: val.id_product,
                                    part_number: val.part_number,
                                    name: val.name,
                                    price: 0,
                                    discount: 0,
                                    qty: 1,
                                });
                            } else {
                                let found = false;

                                for (let i = 0; i < arr.length; i++) {
                                    const element = arr[i];
                                    if (element.part_number == val.part_number) {
                                        found = true;
                                        arr[i].qty += 1;
                                        break;
                                    }
                                }

                                if (found == false) {
                                    arr.push({
                                        id_product: val.id_product,
                                        part_number: val.part_number,
                                        name: val.name,
                                        price: 0,
                                        discount: 0,
                                        qty: 1,
                                    });
                                }
                            }
                        });

                        shelter.push({
                            ...value,
                            include_tax: 0,
                            arr
                        });
                    });

                    this.principles = shelter;

                    this.formInput.id_purchase_request = value.id;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Principle", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Principle", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
        async createPO(data) {
            this.formInput.id_principle = data.id;
            this.formInput.include_tax = data.include_tax;
            this.formInput.products = await data.arr.map((value) => {
                return {
                    id_product: value.id_product,
                    price: value.price,
                    discount: value.discount,
                    qty: value.qty
                }
            }).filter((v) => {
                return v.qty > 0
            });
            if (this.formInput.discount == null) {
                this.$swal.fire("Error", 'The Discount can not be null', "error");
                return;
            }
            if (this.formInput.down_payment == null) {
                this.$swal.fire("Error", 'The Down Payment can not be null', "error");
                return;
            }
            for (let i = 0; i < this.formInput.products.length; i++) {
                if (this.formInput.products[i].discount == null) {
                    this.$swal.fire("Error", 'The Discount can not be null', "error");
                    return;
                }
            }
            this.handleSubmit();
        },
        async handleSubmit() {

            if (!this.formInput.id_purchase_request) {
                this.$swal.fire("Error", 'The No Purchase Request field can not be empty', "error");
                return;
            }

            if (!this.formInput.id_principle) {
                this.$swal.fire("Error", 'The Principle field can not be empty', "error");
                return;
            }

            if (this.formInput.products.length == 0) {
                this.$swal.fire("Error", 'The Products can not be empty', "error");
                return;
            }

            this.$swal.fire({
                title: 'Are you sure save this data?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, save it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.post(
                            "dental-erp-service/purchase_order/purchasing/store",
                            this.formInput, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");

                            this.formInput.id_principle = '';
                            this.formInput.include_tax = 0;
                            this.formInput.products = [];
                            this.requestNumberPO();
                            // this.$router.push('purchase-request');

                        }
                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Error ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        await this.$store.dispatch("setLoading", false);
                    }
                }
            });
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
