<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <!-- <b-button variant="outline-info" v-b-modal.modal-add>
                <b-icon icon="plus-circle-fill" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Register Product
            </b-button> -->
        <router-link class="btn btn-outline-info" to="request-for-quotation" exact>
            <span class="fa fa-arrow-left mr-2"></span> Back to Request For Quotation List
        </router-link>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Create Request For Quotation</h4>
                    </div>
                    <!-- <div class="card-action card-tabs mt-3 mt-sm-0">

                    </div> -->
                </div>
                <div class="card-body">
                    <form ref="formAdd" @submit.prevent="handleSubmit">
                        <div class="form-row">
                            <div class="form-group col-md-6 col-lg-3">
                                <label class="text text-info">No Purchase Request</label>
                                <v-select label="no_pr" :filterable="false" v-model="selected.purchase_request_name" :options="purchase_requests" @search="onSearchPurchaseRequest" @input="setSelectedPurchaseRequest">
                                    <template slot="no-options"> type to search purchase request... </template>
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            {{ option.no_pr }}
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ option.no_pr }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                        </div>
                        <div class="form-row mt-4" v-if="principles.length > 0">
                            <table class="table table-bordered" v-for="(val, index) in principles" :key="index">
                                <thead class="thead-light">
                                    <tr>
                                        <th colspan="2" width="75%">{{ val.name }}</th>
                                        <th class="text-right" width="25%">
                                            <div class="input-group input-primary">
                                            <v-select class="form-control" :options="reduceEmail(val.pic)" v-model="val.email_pic"></v-select>
                                                <div class="input-group-append">
                                                    <button type="button" class="btn btn-info btn-sm" @click="createRFQ(val)">
                                                        <i class="fa fa-plus"></i> Send RFQ
                                                    </button>
                                                </div>
                                            </div>

                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-dark text-white">
                                        <td>Part Number</td>
                                        <td>Product Name</td>
                                        <td>Qty</td>
                                    </tr>
                                    <tr v-for="(product, i) in filterProduct(val.show_products)" :key="'product_' + i">
                                        <td>{{ product.part_number }}</td>
                                        <td>{{ product.name }}</td>
                                        <td>{{ product.qty }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </form>
                </div>
                <!-- <div class="card-footer d-sm-flex justify-content-between align-items-center" v-if="products.length > 0">
                    <div class="card-footer-link mb-4 mb-sm-0">
                        <p class="card-text text-dark d-inline">Total Item: {{ products.length || 0 }} <br> Total Selected Item: {{ selected.length || 0 }} </p>
                    </div>

                    <button @click="handleSubmit" class="btn btn-outline-success" :disabled="selected.length == 0"><i class="fa fa-save mr-1"></i> {{ selected.length == 0 ? 'PLEASE SELECT PRODUCT' : 'SAVE TO STOCK' }}</button>
                </div> -->
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "CreateRequestForQuotation",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Create RFQ | %s',
    },
    data() {
        return {
            purchase_requests: [],
            principles: [],
            selected: {
                purchase_request_name: '',
                principle_name: ''
            },
            formInput: {
                id_purchase_request: '',
                id_principle: '',
                email: '',
                products: []
            },
        };
    },
    methods: {
        reduceEmail(arr) {
            return arr.map(a => a.email)
        },
        filterProduct(data) {
            let arr = [];

            data.forEach(function (value, index) {
                if (index == 0) {
                    arr.push({
                        id_product: value.id_product,
                        part_number: value.part_number,
                        name: value.name,
                        qty: 1,
                    });
                } else {
                    let found = false;

                    for (let i = 0; i < arr.length; i++) {
                        const element = arr[i];
                        if (element.part_number == value.part_number) {
                            found = true;
                            arr[i].qty += 1;
                            break;
                        }
                    }

                    if (found == false) {
                        arr.push({
                            id_product: value.id_product,
                            part_number: value.part_number,
                            name: value.name,
                            qty: 1,
                        });
                    }
                }
            });
            return arr;
        },
        async onSearchPurchaseRequest(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/purchase_request/purchasing", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.purchase_requests = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request RFQ", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request RFQ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedPurchaseRequest(value) {

            if (value == null) {
                this.principles = [];
                this.formInput.id_purchase_request = '';
                return;
            }

            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/purchase_request/purchasing/find_by_principle", {
                        id: value.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.principles = resp.data.data
                    this.formInput.id_purchase_request = value.id;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Principle", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Principle", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
        async createRFQ(data) {
            this.formInput.id_principle = data.id;
            this.formInput.email = data.email_pic;
            this.formInput.products = await data.show_products.map((value) => {
                return {
                    id_product_pr: value.id
                }
            });
            this.handleSubmit();
        },
        async handleSubmit() {

            if (!this.formInput.id_purchase_request) {
                this.$swal.fire("Error", 'The No Purchase Request field can not be empty', "error");
                return;
            }

            if (!this.formInput.id_principle) {
                this.$swal.fire("Error", 'The Principle field can not be empty', "error");
                return;
            }

            if (this.formInput.products.length == 0) {
                this.$swal.fire("Error", 'The Products can not be empty', "error");
                return;
            }

            this.$swal.fire({
                title: 'Are you sure save this data?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, save it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.post(
                            "dental-erp-service/rfq/purchasing/store",
                            this.formInput, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");

                            this.formInput.email = '';
                            this.formInput.id_principle = '';
                            this.formInput.products = [];
                        }
                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Error ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        await this.$store.dispatch("setLoading", false);
                    }
                }
            });
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
