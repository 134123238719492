<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <b-button title="Product Value" class="mr-2" variant="outline-success" to="product-value">
            <i class="fa fa-file-alt"></i>
            Product Value
        </b-button>
        <b-button class="mr-2" variant="outline-warning" v-b-modal.modal-export>
            <!-- <b-icon icon="plus-circle-fill" aria-hidden="true" class="mr-2"></b-icon>  -->
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-telegram mr-2" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
            </svg>
            Product Record
        </b-button>
        <!-- <div class="dropdown custom-dropdown mr-2">
            <button type="button" class="btn btn-outline-primary d-flex align-items-center svg-btn" data-toggle="dropdown" aria-expanded="false" style="border-color: #2f4cdd !important">
                <svg width="16" class="scale5" height="16" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.16647 27.9558C9.25682 27.9856 9.34946 28.0001 9.44106 28.0001C9.71269 28.0001 9.97541 27.8732 10.1437 27.6467L21.5954 12.2248C21.7926 11.9594 21.8232 11.6055 21.6746 11.31C21.526 11.0146 21.2236 10.8282 20.893 10.8282H13.1053V0.874999C13.1053 0.495358 12.8606 0.15903 12.4993 0.042327C12.1381 -0.0743215 11.7428 0.0551786 11.5207 0.363124L0.397278 15.7849C0.205106 16.0514 0.178364 16.403 0.327989 16.6954C0.477614 16.9878 0.77845 17.1718 1.10696 17.1718H8.56622V27.125C8.56622 27.5024 8.80816 27.8373 9.16647 27.9558ZM2.81693 15.4218L11.3553 3.58389V11.7032C11.3553 12.1865 11.7471 12.5782 12.2303 12.5782H19.1533L10.3162 24.479V16.2968C10.3162 15.8136 9.92444 15.4218 9.44122 15.4218H2.81693Z" fill="#2F4CDD" />
                </svg>
                <span class="fs-16 ml-3">Toggle Columns</span>
                <i class="fa fa-angle-down scale5 ml-3"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right">
                <button v-for="(column, index) in columnRender" :key="index" @click="changeColumn(column)" :class="
              column.hidden
                ? 'btn btn-xs btn-outline-warning btn-block'
                : 'btn btn-xs btn-outline-secondary btn-block'
            ">
                    {{ column.label }}
                </button>
            </div>
        </div> -->
        &nbsp;
        <b-button variant="outline-info" v-b-modal.modal-add>
            <b-icon icon="plus-circle-fill" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Add Price List
        </b-button>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Data Price List</h4>
                    </div>
                    <div class="card-action card-tabs mt-3 mt-sm-0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <select class="input-group-text" v-model="paramQuery.column">
                                    <option value="" selected>All Column</option>
                                    <option v-for="(column, index) in columnRender" :key="index" :value="column.field"> {{ column.label }} </option>
                                </select>
                            </div>
                            <input type="text" v-on:keyup="onSearchChange" v-model="paramQuery.search" class="form-control w-50" placeholder="Search...">
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :pagination-options="{
                        enabled: true,
                        dropdownAllowAll: false,
                        nextLabel: 'Next',
                        setCurrentPage: paramQuery.page,
                        prevLabel: 'Previous',
              }" :rows="rows" :columns="columns" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'action'">
                                <b-button title="Edit" variant="outline-info" v-if="props.row.price != null" size="sm" @click="propsEdit = props.row" v-b-modal.modal-edit><i class="fa fa-pencil"></i> Edit </b-button>
                                <!-- &nbsp; -->
                                <!-- <b-button title="Delete" variant="outline-danger" pill size="sm" @click="deleteData(props.row)"><i class="fa fa-trash"></i></b-button> -->
                            </div>
                            <div v-else-if="props.column.field == 'price.selling_price'">
                                <span v-if="props.row.price != null"> {{ props.row.price.selling_price.toLocaleString('id-ID') }} </span>
                            </div>
                            <div v-else-if="props.column.field == 'price.max_discount'">
                                <span v-if="props.row.price != null">
                                    {{ props.row.price.max_discount }} {{ props.row.price.discount_type == "percentage" ? ' %' : ''}}
                                </span>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
    </div>
    <CreatePriceList />
    <EditPriceList :props-row="propsEdit" />
    <ExportRecord />
</div>
</template>

<script>
import axios from "axios";
import CreatePriceList from "./CreatePriceList";
import EditPriceList from "./EditPriceList";
import ExportRecord from "./ExportRecord";
import {
    BButton,
} from "bootstrap-vue";

export default {
    name: "PriceList",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Price List | %s',
    },
    components: {
        CreatePriceList,
        EditPriceList,
        ExportRecord,
        BButton
    },
    data() {
        return {
            columns: [{
                    label: "Part Number",
                    field: "part_number",
                    thClass: 'text-center',
                    tdClass: "text-center",
                    hidden: false,
                },
                {
                    label: "Name",
                    field: "name",
                    // thClass: 'text-center',
                    // tdClass: "text-center",
                    hidden: false,
                },
                {
                    label: "Max Discount",
                    field: "price.max_discount",
                    sortable: false,
                    hidden: false,
                },
                {
                    label: "Purchase Price",
                    field: "price.purchase_price",
                    sortable: false,
                    hidden: false,
                },
                {
                    label: "Selling Price",
                    field: "price.selling_price",
                    sortable: false,
                    hidden: false,
                },
                {
                    label: "Principle",
                    field: "principle_name",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Category",
                    field: "category_name",
                    sortable: false,
                    hidden: false,
                },
                {
                    label: "Brand",
                    field: "brand_name",
                    sortable: false,
                    hidden: false,
                },
                {
                    label: "Piece",
                    field: "piece",
                    hidden: false,
                },
                {
                    label: "Created Date",
                    field: "created_at",
                    thClass: 'text-center',
                    tdClass: "text-center",
                    hidden: false,
                },
                {
                    label: "Action",
                    field: "action",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                    sortable: false,
                },
            ],
            timeout: null,
            rows: [],
            propsEdit: {},
            totalRecords: 0,
            paramQuery: {
                page: 1,
                limit: 10,
                search: "",
                column: "",
                ascending: false,
            },
        };
    },
    computed: {
        columnRender: function () {
            return this.columns.filter(i =>
                i.field != 'action' &&
                i.field != 'principle_name' &&
                i.field != 'category_name' &&
                i.field != 'current_stock' &&
                i.field != 'origin_stock' &&
                i.field != 'price.purchase_price' &&
                i.field != 'price.price.selling_price' &&
                i.field != 'price.max_discount' &&
                i.field != 'brand_name'
            )
        },
    },
    async mounted() {
        await this.getData();
    },
    methods: {
        handleCancel() {
            this.propsEdit = {};
        },

        updateParams(newProps) {
            this.paramQuery = Object.assign({}, this.paramQuery, newProps);
        },

        onPageChange(params) {
            this.updateParams({
                page: params.currentPage
            });
            this.getData();
        },

        onPerPageChange(params) {
            this.updateParams({
                limit: params.currentPerPage,
                page: 1
            });
            this.getData();
        },

        onSortChange(params) {
            this.updateParams({
                column: params[0].field,
                ascending: params[0].type == "desc" ? false : true,
            });
            this.getData();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.getData();
        },

        onSearchChange() {
            this.updateParams({
                page: 1
            });

            clearTimeout(this.timeout);
            var self = this;
            this.timeout = setTimeout(function () {
                self.getData();
            }, 1000);
            // this.getData();
        },

        async getData() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post("dental-erp-service/price_list/purchasing", this.paramQuery, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecords = resp.data.pagination.total;
                    this.rows = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async deleteData(row) {
            this.$swal.fire({
                title: 'Are you sure delete this data?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.delete(
                            "dental-erp-service/price_list/purchasing/destroy", {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                                data: {
                                    id: row.price.id
                                }
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.getData();
                        }

                    } catch (error) {
                        // this.$swal.fire("Error", error.response.data.message, "error");
                        // console.log(error);
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        await this.$store.dispatch("setLoading", false);
                    }
                }
            });
        },
        // async changeColumn(column) {
        //     for (var i in this.columns) {
        //         if (this.columns[i].label == column.label) {
        //             this.columns[i].hidden =
        //                 "hidden" in this.columns[i] ? !this.columns[i].hidden : false;
        //             break;
        //         }
        //     }
        // },
    },
};
</script>
