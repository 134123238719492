<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start" v-if="shelter.length != 0">
        <div class="mr-auto d-lg-block">
            <h2 class="text-black font-w600 mb-0">Purchase Order</h2>
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <router-link class="btn btn-outline-info mr-1" to="purchase-order" exact>
            <span class="fa fa-arrow-left mr-2"></span> Back to Purchase Order List
        </router-link>
        <button class="btn btn-outline-info" @click="generatePDF"> <i class="fa fa-file-pdf-o mr-2"></i> Download Purchase Order </button>
    </div>
    <div class="row" v-if="shelter.get_products !== undefined">
        <div class="col-lg-12">
            <div class="card mt-3">
                <div class="card-header">
                    No Purchase Order: {{ shelter.no_po }}
                    <strong>{{ shelter.date_po }}</strong>
                    <!-- <span class="float-right">
                        <strong>Status: </strong>
                        <span :class=" shelter.paid != 1 ? 'text-danger' : 'text-info' ">{{ shelter.date_paid == null ? 'Unpaid' : 'Paid at ' + shelter.date_paid  }} </span>
                    </span> -->
                </div>
                <div class="card-body">
                    <button v-if="!tableEditable" class="btn btn-sm btn-info" @click="editableState"> Edit Price </button>
                    <template v-else>
                        <div class="row">
                            <button class="btn btn-sm btn-success" @click="handleSubmit"> Confirm </button>
                            <button class="btn btn-sm btn-danger ml-1" @click="editableState"> Cancel </button>
                            <div class="ml-2">
                                <b-form-checkbox id="checkbox-1" v-model="formEdit.include_tax" name="checkbox-1" :value="true" :unchecked-value="false">
                                    Include Tax?
                                </b-form-checkbox>
                            </div>
                        </div>
                    </template>
                    <div class="row mb-5">
                        <div class="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <h4>Purchase To: </h4>
                            <div><strong> {{ shelter.principle.name }} </strong> </div>
                            <div><strong> {{ shelter.principle.email }} </strong> </div>
                            <div><strong> {{ shelter.principle.phone_number }} </strong> </div>
                        </div>
                        <!-- <div class="mt-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 text-right">
                            <h4>Info Purchase Order: </h4>
                            <div>No PO: <strong>{{ shelter.po.no_po }}</strong></div>
                            <div>Due Date: <strong>{{ shelter.po.due_date }}</strong></div>
                            <div>Currency: <strong>{{ shelter.po.detail.currency }}</strong></div>
                            <div v-if="shelter.po.detail.currency != 'IDR'">Rate: <strong>{{ shelter.po.detail.rate }}</strong></div>
                            <div>Down Payment: <strong>{{ shelter.po.detail.down_payment }}</strong></div>
                        </div> -->
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th class="center">#</th>
                                    <th>Part Number</th>
                                    <th>Item</th>
                                    <th class="center">Qty</th>
                                    <th class="center">Price</th>
                                    <th class="center">Discount</th>
                                    <th class="text-right">Total</th>
                                </tr>
                            </thead>
                            <tbody v-if="tableEditable">
                                <tr v-for="(value, index) in formEdit.products" :key="index">
                                    <td class="center">{{ index+1 }}</td>
                                    <td class="left strong">{{ value.part_number }}</td>
                                    <td class="left">{{ value.product_name }}</td>
                                    <td class="center">{{ value.qty }}</td>
                                    <!-- <td class="center">{{shelter.detail.currency}} {{ value.price.toLocaleString('id-ID') || 0 }}</td> -->
                                    <td class="center">
                                        <input type="number" v-model="value.price">
                                    </td>
                                    <!-- <td class="center">{{shelter.detail.currency}} {{ value.discount.toLocaleString('id-ID') || 0 }}</td> -->
                                    <td class="center">
                                        <input type="number" v-model="value.discount">
                                    </td>
                                    <!-- <td class="text-right">{{shelter.detail.currency}} {{ ((value.price * value.qty) - value.discount).toLocaleString('id-ID') || 0 }}</td> -->
                                    <td class="text-right">{{shelter.detail.currency}} {{ ((value.price - value.discount) * value.qty).toLocaleString('id-ID') || 0 }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr v-for="(value, index) in formEdit.products" :key="index">
                                    <td class="center">{{ index+1 }}</td>
                                    <td class="left strong">{{ value.part_number }}</td>
                                    <td class="left">{{ value.product_name }}</td>
                                    <td class="center">{{ value.qty }}</td>
                                    <td class="center">{{shelter.detail.currency}} {{ value.price.toLocaleString('id-ID') || 0 }}</td>
                                    <td class="center">{{shelter.detail.currency}} {{ value.discount.toLocaleString('id-ID') || 0 }}</td>
                                    <!-- <td class="text-right">{{shelter.detail.currency}} {{ ((value.price * value.qty) - value.discount).toLocaleString('id-ID') || 0 }}</td> -->
                                    <td class="text-right">{{shelter.detail.currency}} {{ ((value.price - value.discount) * value.qty).toLocaleString('id-ID') || 0 }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-sm-5"> </div>
                        <div class="col-lg-4 col-sm-5 ml-auto">
                            <table class="table table-clear">
                                <tbody>
                                    <tr>
                                        <td class="text-center"><strong>Subtotal</strong></td>
                                        <td class="text-right">{{shelter.detail.currency}} {{ shelter.detail.sub_total.toLocaleString('id-ID') }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center"><strong>Discount</strong></td>
                                        <td class="text-right">{{shelter.detail.currency}} {{ shelter.detail.discount.toLocaleString('id-ID') }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center"><strong>Tax (11%)</strong></td>
                                        <td class="text-right">{{shelter.detail.currency}} {{ shelter.detail.tax.toLocaleString('id-ID') }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center"><strong>Total</strong></td>
                                        <td class="text-right"><strong>{{shelter.detail.currency}} {{ shelter.detail.total.toLocaleString('id-ID') }}</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <PrintPurchaseOrder :data-purchase-order="shelter" ref="pdfComponent" hidden />
</div>
</template>

<script>
import axios from "axios";
import PrintPurchaseOrder from "./PrintPurchaseOrder";

export default {
    name: "DetailPurchaseOrder",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Detail Purchase Order | %s',
    },
    components: {
        PrintPurchaseOrder
    },
    data() {
        return {
            shelter: {},
            tableEditable: false,
            formEdit: {
                id: '',
                id_principle: '',
                date_po: '',
                due_date: '',
                discount: 0,
                currency: '',
                rate: 1,
                down_payment: 0,
                include_tax: 0,
            }
        };
    },
    // computed: {
    //     filterProduct() {
    //         let arr = [];

    //         if (this.shelter.get_products === undefined) {
    //             return arr;
    //         }

    //         this.shelter.get_products.forEach(function (value, index) {
    //             if (index == 0) {
    //                 arr.push({
    //                     id_product: value.id_product,
    //                     part_number: value.part_number,
    //                     product_name: value.product_name,
    //                     price: value.price,
    //                     discount: value.discount,
    //                     qty: 1,
    //                 });
    //             } else {
    //                 let found = false;

    //                 for (let i = 0; i < arr.length; i++) {
    //                     const element = arr[i];
    //                     if (element.part_number == value.part_number) {
    //                         found = true;
    //                         arr[i].qty += 1;
    //                         arr[i].discount += value.discount;
    //                         break;
    //                     }
    //                 }

    //                 if (found == false) {
    //                     arr.push({
    //                         id_product: value.id_product,
    //                         part_number: value.part_number,
    //                         product_name: value.product_name,
    //                         price: value.price,
    //                         discount: value.discount,
    //                         qty: 1
    //                     });
    //                 }
    //             }
    //         });
    //         return arr;
    //     }
    // },
    async created() {
        await this.onLoad()
    },
    methods: {
        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('invoice-in');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/purchase_order/purchasing/find", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.formEdit.id = resp.data.data.id
                    this.formEdit.id_principle = resp.data.data.id_principle;
                    this.formEdit.date_po = resp.data.data.date_po;
                    this.formEdit.due_date = resp.data.data.due_date;
                    this.formEdit.discount = resp.data.data.detail.discount;
                    this.formEdit.currency = resp.data.data.detail.currency;
                    this.formEdit.rate = resp.data.data.detail.rate;
                    this.formEdit.down_payment = resp.data.data.detail.down_payment;
                    if (resp.data.data.detail.tax == 0) {
                        this.formEdit.include_tax = true;
                    } else {
                        this.formEdit.include_tax = false;
                    }
                    let arr = [];
                    resp.data.data.get_products.forEach(function (value, index) {
                        if (index == 0) {
                            arr.push({
                                id_product: value.id_product,
                                part_number: value.part_number,
                                product_name: value.product_name,
                                price: value.price,
                                discount: value.discount,
                                qty: 1,
                            });
                        } else {
                            let found = false;

                            for (let i = 0; i < arr.length; i++) {
                                const element = arr[i];
                                if (element.part_number == value.part_number) {
                                    found = true;
                                    arr[i].qty += 1;
                                    // arr[i].discount += value.discount;
                                    break;
                                }
                            }

                            if (found == false) {
                                arr.push({
                                    id_product: value.id_product,
                                    part_number: value.part_number,
                                    product_name: value.product_name,
                                    price: value.price,
                                    discount: value.discount,
                                    qty: 1
                                });
                            }
                        }
                    });
                    this.formEdit.products = arr
                    return this.shelter = resp.data.data;
                }

                return this.$router.push('invoice-in');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Detail Invoice Out", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Detail Invoice Out ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        async handleSubmit() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/purchase_order/purchasing/update",
                    this.formEdit, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.onLoad();
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        this.onLoad();
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
                this.tableEditable = !this.tableEditable
            }
        },

        editableState() {
            this.tableEditable = !this.tableEditable
        },
        async generatePDF() {
            this.$refs.pdfComponent.generateReport();
        },
        async paidNow() {
            this.$swal.fire({
                title: 'Are you sure you have paid this invoice?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, of course!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        if (!this.$route.query.id) {
                            return this.$router.push('invoice-out');
                        }

                        await this.$store.dispatch('setLoading', true);
                        const resp = await axios.post(
                            "dental-erp-service/invoice_out/finance/update_payment", {
                                id: this.$route.query.id,
                            }, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            this.onLoad();
                            this.$swal.fire("Success", resp.data.message, "success");
                        }

                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Request Paid Invoice", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request Paid Invoice ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        this.$store.dispatch('setLoading', false);
                    }
                }
            })
        }
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
