<template>
<transition name="fade" mode="out-in" appear>
    <component :is="urlQuery"></component>
</transition>
</template>

<script>
import axios from "axios";
import Dashboard from '../views/PurchasingModule/Dashboard';
import PriceList from '../views/PurchasingModule/PriceList/PriceList';
import CreatePriceList from '../views/PurchasingModule/PriceList/CreatePriceList';
import PurchaseOrder from '../views/PurchasingModule/PurchaseOrder/PurchaseOrder';
import RequestForQuotation from '../views/PurchasingModule/RequestForQuotation/RequestForQuotation';
import CreateRequestForQuotation from '../views/PurchasingModule/RequestForQuotation/CreateRequestForQuotation';
import CreatePurchaseOrder from '../views/PurchasingModule/PurchaseOrder/CreatePurchaseOrder';
import DetailPurchaseOrder from '../views/PurchasingModule/PurchaseOrder/DetailPurchaseOrder';
import PurchaseRequest from '../views/PurchasingModule/PurchaseRequest/PurchaseRequest'
import ProductValue from '../views/PurchasingModule/PriceList/ProductValue'

export default {
    name: 'Purchasing',
    components: {
        Dashboard,
        PriceList,
        CreatePriceList,
        PurchaseOrder,
        RequestForQuotation,
        CreateRequestForQuotation,
        CreatePurchaseOrder,
        DetailPurchaseOrder,
        PurchaseRequest,
        ProductValue
    },
    async created() {
        try {

            await this.$store.dispatch('setLoading', true);
            const resp = await axios.get("user-service/auth/menu?module=purchasing", {
                headers: {
                    Authorization: localStorage.getItem("token") || "",
                },
            });
            if (resp.data.error === false) {
                this.listApplication = resp.data.data;
                await this.$store.dispatch('setMenu', resp.data.data);
                return;
            }
        } catch (error) {
            if (error.response != undefined) {
                if (error.response.data != undefined) {
                    if (error.response.data.error_auth != undefined) {
                        this.$swal.fire("Get Application List", error.response.data.message, "error");
                        this.$store.dispatch("logOut").then(() => {
                            this.$router.push("/login");
                        });
                        return;
                    }
                    return this.$swal.fire("Get Application List ", error.response.data.message, "error");
                }
            }

            this.$sentry.captureException(error);
            this.$swal.fire("Error", error.message, "error");
        } finally {
            this.$store.dispatch("setApp");
            this.$store.dispatch('setLoading', false);
        }
    },
    computed: {
        urlQuery() {
            return this.$route.params.component || 'Dashboard';
        }
    }
}
</script>