<template>
<div>
    <b-modal id="modal-edit" ref="my-modal-edit" title="Form Edit Price List" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk" @hidden="$parent.handleCancel" button-size="sm">
        <form @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-6 col-lg-6">
                    <label class="text text-info">Select Product</label>
                    <v-select disabled label="name" :filterable="false" :options="products" v-model="formInput" @search="onSearchProduct">
                        <template slot="no-options"> type to search product... </template>
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                [{{ option.part_number}}] {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                [{{ option.part_number}}] {{ option.name }}
                            </div>
                        </template>
                    </v-select>
                </div>
                <div class="form-group col-md-3">
                    <label class="text text-info">Purchase Price</label>
                    <!-- <input type="text" class="form-control" required v-model="formInput.purchase_price" /> -->
                    <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model="formInput.purchase_price" />
                </div>
                <div class="form-group col-md-3">
                    <label class="text text-info">Selling Price</label>
                    <!-- <input type="text" class="form-control" required v-model="formInput.selling_price" /> -->
                    <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model="formInput.selling_price" />
                </div>
                <div class="form-group col-md-3">
                    <label class="text text-info">Max Discount</label>
                    <!-- <input type="text" class="form-control" required v-model="formInput.max_discount" /> -->
                    <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model="formInput.max_discount" />
                </div>
                <div class="form-group col-md-3">
                    <label class="text text-info">Discount Type</label>
                    <!-- <input type="text" class="form-control" required v-model="formInput.discount_type" /> -->
                    <v-select :options="discount_types" v-model="formInput.discount_type"></v-select>
                </div>
                <div class="form-group col-md-3">
                    <label class="text text-info">Purchase Currency</label>
                    <!-- <input type="text" class="form-control" required v-model="formInput.purchase_currency" /> -->
                    <v-select :options="currency" v-model="formInput.purchase_currency"></v-select>
                </div>
                <div class="form-group col-md-3">
                    <label class="text text-info">Selling Currency</label>
                    <!-- <input type="text" class="form-control" required v-model="formInput.selling_currency" /> -->
                    <v-select :options="currency" v-model="formInput.selling_currency"></v-select>
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "EditPriceList",
    props: {
        propsRow: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            products: [],
            discount_types: ['percentage', 'nominal'],
            currency: ['IDR', 'USD', 'EUR', 'JPY', 'CNY', 'KWD', 'BHD', 'OMR', 'JOD', 'GBP', 'KYD', 'CHF', 'CAD', 'AUD', 'LYD', 'AZN'],
            formInput: {},
        };
    },
    watch: {
        propsRow: {
            immediate: true,
            async handler() {
                this.formInput = Object.assign({}, {name: this.propsRow.name, part_number: this.propsRow.part_number, ...this.propsRow.price});
            },
        },
    },
    methods: {
        async onSearchProduct(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/price_list/purchasing", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.products = resp.data.data;
                }
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request Principle"
                //     );
                // }
                // return await this.$toastr.e(error, "Request Principle");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },
        async handleSubmit() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/price_list/purchasing/update",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    await this.$bvModal.hide('modal-edit');
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.$parent.getData();
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
