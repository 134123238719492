<template>
<div class="container-fluid">
    <!-- <MenuApp /> -->
    <div class="row mt-5">
        <div class="col-lg-12 col-xl-12 col-md-12">
            <div class="row">
                <div class="col-lg-3 pl-1 pr-1">
                    <div class="widget-stat card card-information">
                        <div class="card-body p-4">
                            <div class="media ai-icon">
                                <span class="mr-3 text-primary">
                                    <img src="/assets/icon/Digital-Document.png" style="width:70px;height:70px">
                                </span>
                                <div class="media-body">
                                    <h3 class="mb-0 text-black">
                                        <span class="counter ml-0">{{totalPurchaseOrder}}</span>
                                    </h3>
                                    <p class="mb-0">Total PO</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 pl-1 pr-1">
                    <div class="widget-stat card card-information">
                        <div class="card-body p-4">
                            <div class="media ai-icon">
                                <span class="mr-3 text-primary">
                                    <img src="/assets/icon/Task.png" style="width:70px;height:70px">
                                </span>
                                <div class="media-body">
                                    <h3 class="mb-0 text-black">
                                        <span class="counter ml-0">{{totalPendingPurchase}}</span>
                                    </h3>
                                    <p class="mb-0">Pending Purchase</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header border-0 pb-0 d-sm-flex d-block">
                            <div>
                                <h4 class="card-title mb-1">Warehouse Summary</h4>
                            </div>
                        </div>
                        <div class="card-body orders-summary">
                            <div class="row">
                                <div class="col-sm-4 mb-4">
                                    <div class="border px-3 py-3 rounded-xl">
                                        <h2 class="fs-32 font-w600 counter">{{totalPurchaseOrder}}</h2>
                                        <p class="fs-16 mb-0">Total Purchase Order</p>
                                    </div>
                                </div>
                                <div class="col-sm-4 mb-4">
                                    <div class="border px-3 py-3 rounded-xl">
                                        <h2 class="fs-32 font-w600 counter">{{totalPurchaseRequest}}</h2>
                                        <p class="fs-16 mb-0">Total Sales Request</p>
                                    </div>
                                </div>
                                <div class="col-sm-4 mb-4">
                                    <div class="border px-3 py-3 rounded-xl">
                                        <h2 class="fs-32 font-w600 counter">{{pendingRFQ}}</h2>
                                        <p class="fs-16 mb-0">Pending RFQ</p>
                                    </div>
                                </div>
                            </div>
                            <div class="widget-timeline-icon">
                                <div class="row align-items-center mx-0">
                                    <div class="col-xl-9 col-lg-8 col-xxl-8 col-sm-8 px-0">
                                        <div class="d-flex align-items-center mb-3">
                                            <p class="mb-0 fs-14 mr-2 col-4 px-0">Total Purchase Request</p>
                                            <div class="progress mb-0" style="height: 8px; width: 100%">
                                                <div class="progress-bar bg-warning progress-animated" style="width: 85%; height: 8px" role="progressbar">
                                                    <span class="sr-only">60% Complete</span>
                                                </div>
                                            </div>
                                            <span class="pull-right ml-auto col-1 px-0 text-right">35</span>
                                        </div>
                                        <div class="d-flex align-items-center mb-3">
                                            <p class="mb-0 fs-14 mr-2 col-4 px-0">Total Sales Order</p>
                                            <div class="progress mb-0" style="height: 8px; width: 100%">
                                                <div class="progress-bar bg-success progress-animated" style="width: 70%; height: 8px" role="progressbar">
                                                    <span class="sr-only">60% Complete</span>
                                                </div>
                                            </div>
                                            <span class="pull-right ml-auto col-1 px-0 text-right">70</span>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p class="mb-0 fs-14 mr-2 col-4 px-0">Total Delivery Order</p>
                                            <div class="progress mb-0" style="height: 8px; width: 100%">
                                                <div class="progress-bar bg-dark progress-animated" style="width: 30%; height: 8px" role="progressbar">
                                                    <span class="sr-only">60% Complete</span>
                                                </div>
                                            </div>
                                            <span class="pull-right ml-auto col-1 px-0 text-right">90</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-xl-6">
            <div class="card">
                <div class="card-header bg-info">
                    <div>
                        <h4 class="card-title text-white">Principle with highest Purchase Order</h4>
                    </div>
                    <!-- <div class="card-action card-tabs mt-3 mt-sm-0">
                        <div class="input-group">
                            <input type="text" v-on:keyup="onSearchChange" v-model="paramQuery.search" class="form-control w-50" placeholder="Search...">
                        </div>
                    </div> -->
                </div>
                <div style="zoom:136%" class="card-body">
                    <LineChart />
                </div>
            </div>
        </div>
        <div class="col-xl-6">
            <div class="card">
                <div class="card-header bg-info">
                    <div>
                        <h4 class="card-title text-white">Product Information</h4>
                    </div>
                    <!-- <div class="card-action card-tabs mt-3 mt-sm-0">
                        <div class="input-group">
                            <input type="text" v-on:keyup="onSearchChange" v-model="paramQuery.search" class="form-control w-50" placeholder="Search...">
                        </div>
                    </div> -->
                </div>
                <div class="card-body">
                    <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecordsProduct" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                nextLabel: 'Next',
                prevLabel: 'Previous',
                setCurrentPage: paramQuery.page,
                perPage: 5,
              }" :rows="rowsProduct" :columns="columnsProduct" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'fullname'">
                                <div class="d-flex align-items-center">
                                    <img :src="props.row.profile" class="rounded-lg mr-2" width="24" alt="" />
                                    <span class="w-space-no">{{props.row.fullname}}</span>
                                </div>
                            </div>
                            <div v-else-if="props.column.field == 'origin_stock'">
                                {{props.row.origin_stock}}
                            </div>
                            <div v-else-if="props.column.field == 'current_stock'">
                                {{props.row.current_stock}}
                            </div>
                            <div v-else-if="props.column.field == 'outgoing_stock'">
                                {{props.row.outgoing_stock}}
                            </div>
                            <div v-else-if="props.column.field == 'piece'">
                                <span class="badge badge-rounded badge-primary">{{props.row.piece}}</span>
                            </div>
                            <div v-else-if="props.column.field == 'action'">
                                <b-button title="Edit" variant="outline-info" pill size="sm" @click="propsEdit = props.row" v-b-modal.modal-edit><i class="fa fa-pencil"></i></b-button>
                                &nbsp;
                                <b-button title="Delete" variant="outline-danger" pill size="sm" @click="deleteData(props.row)"><i class="fa fa-trash"></i></b-button>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
        <div class="col-xl-6">
            <div class="card">
                <div class="card-header d-block d-sm-flex bg-info">
                    <div>
                        <h4 class="card-title text-white">Purchase Order Pending</h4>
                    </div>
                    <!-- <div class="card-action card-tabs mt-3 mt-sm-0">
                        <div class="input-group">
                            <input type="text" v-on:keyup="onSearchChange" v-model="paramQueryPurchaseOrder.search" class="form-control w-50" placeholder="Search...">
                        </div>
                    </div> -->
                </div>
                <div class="card-body">
                    <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChangePurchase" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChangePurchase" :totalRows="totalRecordsPurchaseOrder" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                nextLabel: 'Next',
                prevLabel: 'Previous',
                setCurrentPage: paramQueryPurchaseOrder.page,
                perPage: 5,
              }" :rows="rowsPurchaseOrder" :columns="columnsPurchaseOrder" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'action'">
                                <b-button title="Detail" variant="outline-info" size="sm" :to="'detail-purchase-order?id='+ props.row.id"><i class="fa fa-search"></i> Detail </b-button>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
        <div class="col-xl-6">
            <!-- <div style="height:70%;" class="card">
                <div class="card-header bg-warning">
                    <div>
                        <h4 class="card-title text-white">Near Expired Product</h4>
                    </div>
                    <div class="card-action card-tabs mt-3 mt-sm-0">
                        <div class="input-group">
                            <input type="text" class="form-control w-50" placeholder="Search...">
                        </div>
                    </div>
                </div>
                <div style="height:100%;overflow:scroll;" class="card-body tab-content pt-3">
                    <div class="media items-list-1">
                        <a><img class="img-fluid rounded mr-3" width="85" src="/assets/logo/erp-dental.png" alt="DexignZone"></a>
                        <div class="media-body col-sm-4 col-6 col-xxl-5 px-0">
                            <h5 class="mt-0 mb-3"><a class="text-black">[6034] Reamer 21mm size 35 /6pcs</a></h5>
                            <strong class="text-secondary mr-2">110 Available in Stock</strong>
                        </div>
                        <div class="media-footer ml-auto col-3 px-0 d-flex align-self-center align-items-center">
                            <div class="mr-3">
                            </div>
                            <div>
                                <h3 class="mb-2 font-w600 text-black">20/09/2021</h3>
                            </div>
                        </div>
                    </div>
                    <div class="media items-list-1">
                        <a><img class="img-fluid rounded mr-3" width="85" src="/assets/logo/erp-dental.png" alt="DexignZone"></a>
                        <div class="media-body col-sm-4 col-6 col-xxl-5 px-0">
                            <h5 class="mt-0 mb-3 text-black"><a class="text-black">[17013-10] BARBED BROACH-C(COARSE) BLACK/10</a></h5>
                            <strong class="text-secondary mr-2">74 Available in Stock</strong>
                        </div>
                        <div class="media-footer ml-auto col-3 px-0 d-flex align-self-center align-items-center">
                            <div class="mr-3">
                            </div>
                            <div>
                                <h3 class="mb-2 font-w600 text-black">01/10/2021</h3>
                            </div>
                        </div>
                    </div>
                    <div class="media items-list-1">
                        <a><img class="img-fluid rounded mr-3" width="85" src="/assets/logo/erp-dental.png" alt="DexignZone"></a>
                        <div class="media-body col-sm-4 col-6 col-xxl-5 px-0">
                            <h5 class="mt-0 mb-3 text-black"><a class="text-black" href="ecom-product-detail.html">[34967] Refill - Revolution 2 A1 2G</a></h5>
                            <strong class="text-secondary mr-2">146 Available in Stock</strong>
                        </div>
                        <div class="media-footer ml-auto col-3 px-0 d-flex align-self-center align-items-center">
                            <div class="mr-3">
                            </div>
                            <div>
                                <h3 class="mb-2 font-w600 text-black">08/10/2021</h3>
                            </div>
                        </div>
                    </div>
                    <div class="media items-list-1">
                        <a><img class="img-fluid rounded mr-3" width="85" src="/assets/logo/erp-dental.png" alt="DexignZone"></a>
                        <div class="media-body col-sm-4 col-6 col-xxl-5 px-0">
                            <h5 class="mt-0 mb-3 text-black"><a class="text-black">[12772] Hedstrom Files 21mm size 25 Red</a></h5>
                            <strong class="text-secondary mr-2">39 Available in Stock</strong>
                        </div>
                        <div class="media-footer ml-auto col-3 px-0 d-flex align-self-center align-items-center">
                            <div class="mr-3">
                            </div>
                            <div>
                                <h3 class="mb-2 font-w600 text-black">25/10/2021</h3>
                            </div>
                        </div>
                    </div>
                    <div class="media items-list-1">
                        <a><img class="img-fluid rounded mr-3" width="85" src="/assets/logo/erp-dental.png" alt="DexignZone"></a>
                        <div class="media-body col-sm-4 col-6 col-xxl-5 px-0">
                            <h5 class="mt-0 mb-3 text-black"><a class="text-black" href="ecom-product-detail.html">[821-3025] TRIPLE-FLEX FILES 25mm Assort 15-40</a></h5>
                            <strong class="text-secondary mr-2">98 Available in Stock</strong>
                        </div>
                        <div class="media-footer ml-auto col-3 px-0 d-flex align-self-center align-items-center">
                            <div class="mr-3">
                            </div>
                            <div>
                                <h3 class="mb-2 font-w600 text-black">15/10/2021</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="card">
                <div class="card-header bg-warning">
                    <div class="mr-3">
                        <h4 class="card-title text-white">You have {{ExpiredList.grand_total.qty_total}} Product That Will Expired Soon</h4>
                    </div>
                    <!-- <div class="card-action card-tabs mt-3 mt-sm-0">
                        <div class="input-group">
                            <input type="text" v-on:keyup="onSearchChangeExpired" v-model="paramQueryExpired.search" class="form-control w-50" placeholder="Search...">
                        </div>
                    </div> -->
                </div>
                <div class="card-body tab-content pt-3">
                    <div v-for="(expired, index) in ExpiredList.data" :key="index" class="media items-list-1">
                        <a><img class="img-fluid rounded mr-3" width="85" src="/assets/logo/erp-dental.png" alt="DexignZone"></a>
                        <div class="media-body col-sm-4 col-6 col-xxl-5 px-0">
                            <h5 class="mt-0 mb-3"><a class="text-black">[{{expired.part_number}}] {{expired.name}}</a></h5>
                            <strong class="text-secondary mr-2">{{expired.qty}} Available in Stock</strong>
                        </div>
                        <div class="media-footer ml-auto col-3 px-0 d-flex align-self-center align-items-center">
                            <div class="mr-3">
                            </div>
                            <div>
                                <h3 class="mb-2 font-w600 text-black">{{expired.expired_date}}</h3>
                            </div>
                        </div>
                    </div>
                    <footer style="text-align:right;">
                        <div class="btn-group" role="group">
                            <button v-if="paramQueryExpired.page == 1" disabled type="button" class="btn btn-info">
                                <b-icon-arrow-left-circle-fill></b-icon-arrow-left-circle-fill>
                            </button>
                            <button v-else type="button" class="btn btn-info" @click="ExpiredPreviousButtonClick">
                                <b-icon-arrow-left-circle-fill></b-icon-arrow-left-circle-fill>
                            </button>
                            <button v-if="paramQueryExpired.page >= ExpiredList.pagination.total_pages" disabled type="button" class="btn btn-info">
                                <b-icon-arrow-right-circle-fill></b-icon-arrow-right-circle-fill>
                            </button>
                            <button v-else type="button" class="btn btn-info" @click="ExpiredNextButtonClick">
                                <b-icon-arrow-right-circle-fill></b-icon-arrow-right-circle-fill>
                            </button>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// import MenuApp from '../../components/MenuApp';
import LineChart from '../../components/LineChart'
import axios from "axios";

export default {
    name: "Dashboard",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Dashboard Purchasing | %s',
    },
    components: {
        // MenuApp,
        LineChart
    },
    data() {
        return {
            columnsProduct: [{
                    label: "Part Number",
                    field: "part_number",
                    hidden: false,
                },
                {
                    label: "Name",
                    field: "name",
                    hidden: false,
                },
                {
                    label: "Origin Stock",
                    field: "origin_stock",
                    type: 'number',
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Outgoing Stock",
                    field: "outgoing_stock",
                    type: 'number',
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Current Stock",
                    field: "current_stock",
                    type: 'number',
                    hidden: false,
                    sortable: false,
                },
            ],
            columnsPurchaseOrder: [{
                    label: "No PO",
                    field: "no_po",
                    hidden: false,
                },
                {
                    label: "Qty",
                    field: "qty",
                    hidden: false,  
                    sortable: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                },
                {
                    label: "Received",
                    field: "qty_received",
                    hidden: false,  
                    sortable: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                },
                {
                    label: "Total",
                    field: "total_ammount",
                    hidden: false,  
                    sortable: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                },
                {
                    label: "Currency",
                    field: "currency",
                    hidden: false,  
                    sortable: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                },
                {
                    label: "Action",
                    field: "action",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                    sortable: false,
                },
            ],
            totalPurchaseOrder: 0,
            totalPendingPurchase: 0,
            timeout: null,
            pendingRFQ: 0,
            ExpiredList: [],
            rowsProduct: [],
            rowsPurchaseOrder: [],
            totalRecordsProduct: 0,
            totalRecordsPurchaseOrder: 0,
            paramQuery: {
                page: 1,
                limit: 5,
                search: "",
                column: "",
                ascending: false,
                id_principle: "",
                id_category: "",
                id_brand: ""
            },
            paramQueryPurchaseOrder: {
                page: 1,
                limit: 5,
                search: "",
                column: "",
                ascending: false,
            },
            paramQueryExpired: {
                page: 1,
                limit: 5,
                search: "",
                column: "",
                ascending: false
            },
        };
    },
    computed: {
        // columnRenderProduct: function () {
        //     return this.columnsProduct.filter(i =>
        //         i.field != 'action' &&
        //         i.field != 'principle_name' &&
        //         i.field != 'category_name' &&
        //         i.field != 'current_stock' &&
        //         i.field != 'origin_stock' &&
        //         i.field != 'brand_name'
        //     )
        // },

        // columnRenderPurchaseOrder: function () {
        //     return this.columns.filter(i => i.field != 'action' && i.field != 'no_so' && i.field != 'product_in_so' && i.field != 'product_in_do' && i.field != 'product_do_pending')
        // },
    },
    methods: {
        async getData() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.get('dental-erp-service/counting/purchase_order', {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalPurchaseOrder = resp.data.data.total_all;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getProductPurchase() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.get('dental-erp-service/counting/product_purchase', {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalPendingPurchase = resp.data.data.total_pending;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getProduct() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/product", this.paramQuery, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecordsProduct = resp.data.pagination.total;
                    this.rowsProduct = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        async getPurchaseOrder() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/dashboard/po_list", this.paramQueryPurchaseOrder, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecordsPurchaseOrder = resp.data.pagination.total;
                    this.rowsPurchaseOrder = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        async getExpiredList() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/dashboard/expired_list", this.paramQueryExpired, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.ExpiredList = resp.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        updateParams(newProps) {
            this.paramQuery = Object.assign({}, this.paramQuery, newProps);
            // this.paramQueryPurchaseOrder = Object.assign({}, this.paramQueryPurchaseOrder, newProps);
        },

        updateParamsPurchase(newProps) {
            // this.paramQuery = Object.assign({}, this.paramQuery, newProps);
            this.paramQueryPurchaseOrder = Object.assign({}, this.paramQueryPurchaseOrder, newProps);
        },

        onPageChange(params) {
            this.updateParams({
                page: params.currentPage
            });
            this.getProduct();
        },

        onPageChangePurchase(params) {
            this.updateParamsPurchase({
                page: params.currentPage
            });
            this.getPurchaseOrder();
        },

        onPerPageChange(params) {
            this.updateParams({
                limit: params.currentPerPage,
                page: 1
            });
            this.getProduct();
            // this.getPurchaseOrder();
        },

        onPerPageChangePurchase(params) {
            this.updateParamsPurchase({
                limit: params.currentPerPage,
                page: 1
            });
            // this.getProduct();
            this.getPurchaseOrder();
        },

        onSortChange(params) {
            this.updateParams({
                column: params[0].field,
                ascending: params[0].type == "desc" ? false : true,
            });
            this.getData();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.getProduct();
            this.getPurchaseOrder();
        },

        onSearchChange() {
            this.updateParams({
                page: 1
            });

            clearTimeout(this.timeout);
            var self = this;
            this.timeout = setTimeout(function () {
                self.getProduct();
                self.getPurchaseOrder();
            }, 1000);
            // this.getProduct();
            // this.getPurchaseOrder();
        },

        ExpiredNextButtonClick() {
            this.paramQueryExpired.page = this.paramQueryExpired.page + 1
            this.getExpiredList();
        },

        ExpiredPreviousButtonClick() {
            this.paramQueryExpired.page = this.paramQueryExpired.page - 1
            this.getExpiredList();
        },

        updateParamsExpired(newProps) {
            this.paramQueryExpired = Object.assign({}, this.paramQueryExpired, newProps);
        },

        onSearchChangeExpired() {
            this.updateParamsExpired({
                page: 1
            });
            
            clearTimeout(this.timeout);
            var self = this;
            this.timeout = setTimeout(function () {
                self.getExpiredList();
            }, 1000);
            // this.getExpiredList();
        },
    },

    async mounted() {
        await this.$store.dispatch("setApp");
        await this.getData();
        await this.getProductPurchase();
        await this.getProduct();
        await this.getPurchaseOrder();
        await this.getExpiredList();
    },
};
</script>

<style>
.card-information {
    box-shadow: 3px 3px 3px rgb(205, 205, 212);
    background-color: #F7F7F7;
}
</style>
