<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <!-- <b-button variant="outline-info" @click="filterData = !filterData">
            Filter Data
            <b-icon-arrow-bar-down></b-icon-arrow-bar-down>
        </b-button> -->
        <div class="mr-auto d-none d-lg-block">
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header d-block d-sm-flex">
                    <div>
                        <h4 class="card-title">Filter Report</h4>
                    </div>
                </div>
                <div class="card-body">
                    <form>
                        <div class="form-row">
                            <div class="form-group col-md-4 col-lg-4">
                                <label class="text">Product</label>
                                <div class="input-group">
                                    <v-select style="width:100%" label="name" :clearable="false" :filterable="false" :options="productsList" @input="selectedProduct" @search="onSearchProduct">
                                        <template slot="no-options"> type to search product... </template>
                                        <template slot="option" slot-scope="option">
                                            <div class="d-center">
                                                [{{ option.part_number}}] {{ option.name }}
                                            </div>
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                            <div class="selected d-center">
                                                {{ option.name }}
                                            </div>
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                            <div class="form-group col-md-4 col-lg-4">
                                <label class="text">Warehouse</label>
                                <div class="input-group">
                                    <v-select style="width:100%" label="name" :filterable="false" :options="warehousesList" @input="selectedWarehouse" @search="onSearchWarehouse">
                                        <template slot="no-options"> type to search warehouse... </template>
                                        <template slot="option" slot-scope="option">
                                            <div class="d-center">
                                                {{ option.name }}
                                            </div>
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                            <div class="selected d-center">
                                                {{ option.name }}
                                            </div>
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                            <div class="form-group col-md-3 col-lg-3">
                                <ul class="list-group">
                                    <li class="list-group-item" v-for="(val, index) in productShelter" :key="index">
                                        {{val.name}}
                                        <div class="pull-right">
                                            <b-button size="xs" variant="danger" @click="removeProduct(index)">
                                                <i class="fa fa-trash"></i>
                                            </b-button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="form-group col-md-2 col-lg-2">
                                <ul class="list-group">
                                    <li class="list-group-item" v-for="(val, index) in warehouseShelter" :key="index">
                                        {{val.name}}
                                        <div class="pull-right">
                                            <b-button size="xs" variant="danger" @click="removeWarehouse(index)">
                                                <i class="fa fa-trash"></i>
                                            </b-button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="form-group col-md-4 col-lg-4">
                                <!-- Empty Space -->
                            </div>
                            <div class="form-group col-md-12 col-lg-12">
                                <b-button variant="outline-info" @click="getData">
                                    Filter
                                </b-button>
                                <b-button class="ml-3" variant="outline-danger" @click="clearInput">
                                    Clear
                                </b-button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <div class="card bg-success">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <span class="text-white" style="font-size: 110%">Total Quantity Available :</span>
                        </div>
                        <div class="col-12">
                            <span class="text-white" style="font-size: 150%;font-weight: bold">{{grandTotal.total_qty_available}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="card bg-success">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <span class="text-white" style="font-size: 110%">Total Product Value :</span>
                        </div>
                        <div class="col-12">
                            <span class="text-white" style="font-size: 150%;font-weight: bold">Rp. {{grandTotal.total_product_value.toLocaleString('id-ID')}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="card bg-success">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <span class="text-white" style="font-size: 110%">Total Purchase Price :</span>
                        </div>
                        <div class="col-12">
                            <span class="text-white" style="font-size: 150%;font-weight: bold">Rp. {{grandTotal.total_purchase.toLocaleString('id-ID')}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Product Value</h4>
                    </div>
                    <div class="card-action card-tabs mt-3 mt-sm-0">
                        <div class="input-group">
                            <!-- <div class="input-group-prepend">
                                <select class="input-group-text" v-model="paramQuery.column">
                                    <option value="" selected>All Column</option>
                                    <option v-for="(column, index) in columnRender" :key="index" :value="column.field"> {{ column.label }} </option>
                                </select>
                            </div>
                            <input type="text" v-on:keyup="onSearchChange" v-model="paramQuery.search" class="form-control w-50" placeholder="Search..."> -->
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                setCurrentPage: paramQuery.page,
                nextLabel: 'Next',
                prevLabel: 'Previous',
                setCurrentPage: paramQuery.page,
              }" :rows="rows" :columns="columns" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'purchase_price_piece'">
                                <span>{{ props.row.purchase_price_piece.toLocaleString('id-ID') }}</span>
                            </div>
                            <div v-else-if="props.column.field == 'total_purchase'">
                                <span>{{ props.row.total_purchase.toLocaleString('id-ID') }}</span>
                            </div>
                            <div v-else-if="props.column.field == 'sale_price_piece'">
                                <span v-if="props.row.sale_price_piece != null">{{ props.row.sale_price_piece.toLocaleString('id-ID') }}</span>
                                <span v-else>-</span>
                            </div>
                            <div v-else-if="props.column.field == 'total_sale_price'">
                                <span v-if="props.row.total_sale_price != null">{{ parseFloat(props.row.total_sale_price).toLocaleString('id-ID') }}</span>
                                <span v-else>-</span>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";

export default {
    name: "ProductValue",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Product Value | %s',
    },
    data() {
        return {
            columns: [{
                    label: "Part Number",
                    field: "part_number",
                    hidden: false,
                },
                {
                    label: "Product Name",
                    field: "product_name",
                    hidden: false,
                },
                {
                    label: "Warehouse",
                    field: "warehouse",
                    hidden: false,
                },
                {
                    label: "Unit Purchase Price",
                    field: "purchase_price_piece",
                    hidden: false,
                },
                {
                    label: "Unit Sale Price",
                    field: "sale_price_piece",
                    hidden: false,
                },
                {
                    label: "Total Purchase",
                    field: "total_purchase",
                    hidden: false,
                },
                {
                    label: "Total Value",
                    field: "total_sale_price",
                    hidden: false,
                },
            ],
            filterData: false,
            rows: [],
            productsList: [],
            warehousesList: [],
            propsImport: {},
            grandTotal: {},
            totalRecords: 0,
            paramQuery: {
                page: 1,
                limit: 10,
                ascending: false,
                warehouses: [],
                products: [],
                column: ""
            },
            inputShelter: {
                warehouse: {},
                product: {}
            },
            productShelter: [],
            warehouseShelter: [],
        };
    },
    // computed: {
    // columnRender: function () {
    //     return this.columns.filter(i => i.field != 'action')
    // },
    // },
    async mounted() {
        await this.getData();
    },
    methods: {

        containsObjectnonArray(obj, list) {
            var i;
            for (i = 0; i < list.length; i++) {
                if (list[i] === obj) {
                    return true;
                }
            }
            return false;
        },
        containsObjectArray(obj, list) {
            var i;
            for (i = 0; i < list.length; i++) {
                if (list[i].id === obj.id) {
                    return true;
                }
            }
            return false;
        },

        async removeProduct(index) {
            this.productShelter.splice(index, 1);
            this.paramQuery.products.splice(index, 1);
        },

        async removeWarehouse(index) {
            this.warehouseShelter.splice(index, 1);
            this.paramQuery.warehouses.splice(index, 1);
        },

        clearInput() {
            this.paramQuery = {
                page: 1,
                limit: 10,
                ascending: false,
                warehouses: [],
                products: [],
                column: ""
            }
            this.getData();
        },

        updateParams(newProps) {
            this.paramQuery = Object.assign({}, this.paramQuery, newProps);
        },

        onPageChange(params) {
            this.updateParams({
                page: params.currentPage
            });
            this.getData();
        },

        onPerPageChange(params) {
            this.updateParams({
                limit: params.currentPerPage,
                page: 1
            });
            this.getData();
        },

        onSortChange(params) {
            this.updateParams({
                column: params[0].field,
                ascending: params[0].type == "desc" ? false : true,
            });
            this.getData();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.getData();
        },

        // onSearchChange() {
        //     this.updateParams({
        //         page: 1
        //     });
        //     this.getData();
        // },

        async selectedProduct(selected) {
            this.inputShelter.product.id = selected.id
            this.inputShelter.product.name = selected.name
            if (this.containsObjectArray(this.inputShelter.product, this.productShelter)) {
                return
            }
            this.productShelter.push({
                ...this.inputShelter.product
            })
            this.paramQuery.products.push(this.inputShelter.product.id)
        },

        async selectedWarehouse(selected) {
            this.inputShelter.warehouse.id = selected.id
            this.inputShelter.warehouse.name = selected.name
            if (this.containsObjectArray(this.inputShelter.warehouse, this.warehouseShelter)) {
                return
            }
            this.warehouseShelter.push({
                ...this.inputShelter.warehouse
            })
            this.paramQuery.warehouses.push(this.inputShelter.warehouse.id)
        },

        async onSearchProduct(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/product", {
                        limit: 10,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.productsList = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async onSearchWarehouse(query) {
            try {
                const resp = await axios.post(
                    "dental-erp-service/warehouse", {
                        limit: 10,
                        page: 1,
                        ascending: false,
                        search: query
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.warehousesList = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async getData() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/purchase_order/purchasing/report", this.paramQuery, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecords = resp.data.pagination.total;
                    this.rows = resp.data.data;
                    this.grandTotal = resp.data.grand_total;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async changeColumn(column) {
            for (var i in this.columns) {
                if (this.columns[i].label == column.label) {
                    this.columns[i].hidden =
                        "hidden" in this.columns[i] ? !this.columns[i].hidden : false;
                    break;
                }
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
