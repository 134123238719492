<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  mounted() {
    this.renderChart(
      {
        labels: [
          "Bali Dental Clinic",
          "KaVo Dental GmbH",
          "PT Mentari Murni Mulia",
          "Nobel Biocare",
          "Kalmed Sejahtera Indonesia"
        ],
        datasets: [
          {
            label: "Incoming",
            backgroundColor: "#f87979",
            data: [50, 43, 34, 39, 21],
          },
          {
            label: "on Process",
            backgroundColor: "#4BB543",
            data: [25, 18, 20, 31, 19],
          },
        ],
      },
      // { responsive: true, maintainAspectRatio: false, aspectRatio: 2 }
      { responsive: true , maintainAspectRatio: false, intersect: true}
    );
  },
};
</script>