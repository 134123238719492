<template>
<div>
    <vue-html2pdf :show-layout="false" :float-layout="false" :enable-download="true" :preview-modal="false" :paginate-elements-by-height="2000" :filename="'Invoice ' + this.shelter.no_po === undefined ? '-' : this.shelter.no_po" :pdf-quality="2" :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait" pdf-content-width="97%" ref="html2Pdf" v-if="shelter.preview !== undefined">
        <section slot="pdf-content">
            <div class="container-fluid invoice-container">
                <header>
                    <div class="row align-items-center">
                        <div class="col-sm-2 text-center text-sm-right">
                            <img id="logo" width="80px" height="80px" src="/assets/logo/gratia logo.png" />
                        </div>
                        <div class="col-sm-5 text-center text-sm-left mb-3 mb-sm-0" style="font-size: 10px;">
                            <h5 class="mb-0">{{ shelter.preview.from.name }}</h5>
                            <p class="mb-0">NPWP: {{ shelter.preview.from.npwp }}</p>
                            <p class="mb-0">Phone: {{ shelter.preview.from.phone }}</p>
                            <p class="mb-0">Email: {{ shelter.preview.from.email }}</p>
                            <p class="mb-0">Website: {{ shelter.preview.from.website }}</p>
                        </div>
                        <div class="col-sm-5 text-center text-sm-right">
                            <h4 class="mb-0">Purchase Order</h4>
                            <p class="mb-0">{{ shelter.preview.no_po }}</p>
                            <p class="mb-0">Date: {{ shelter.preview.date_po | moment("DD MMMM YYYY") }}</p>
                        </div>
                    </div>
                    <hr>
                </header>
                <main>
                    <div class="row">
                        <div class="col-sm-6 text-sm-right order-sm-1"> <strong>Purchase By:</strong>
                            <address>
                                {{ shelter.preview.from.name }}<br />
                                {{ shelter.preview.from.address }}<br />
                                NPWP: {{ shelter.preview.from.npwp }}
                            </address>
                        </div>
                        <div class="col-sm-6 order-sm-0"> <strong>Purchase To:</strong>
                            <address>
                                {{ shelter.preview.to.name }}<br />
                                {{ shelter.preview.to.email }}<br />
                                {{ shelter.preview.to.phone }}<br />
                                {{ shelter.preview.to.address }}
                            </address>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-sm-6 mb-3"> <strong>Payment Method:</strong><br>
                            <span>{{ shelter.type[0].toUpperCase() + shelter.type.substr(1) }}</span> </div>
                        <div class="col-sm-6 mb-3 text-sm-right"> <strong>Invoice Date:</strong><br>
                            <span>{{ shelter.preview.invoice_date | moment("DD/MM/YYYY") }}</span> </div>
                    </div> -->
                    <div class="row">
                        <div class="col-sm-12">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td class="text-center border-top-0"><strong>No</strong></td>
                                        <td class="border-top-0"><strong>Part No</strong></td>
                                        <td class="border-top-0"><strong>Description</strong></td>
                                        <td class="text-center border-top-0"><strong>Qty</strong></td>
                                        <td class="text-center border-top-0"><strong>Unit Price</strong></td>
                                        <td class="text-center border-top-0"><strong>Discount</strong></td>
                                        <td class="text-right border-top-0"><strong>Total</strong></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(value, index) in filterProduct" :key="index">
                                        <td class="text-center">{{ index + 1 }}</td>
                                        <td>{{ value.part_number }}</td>
                                        <td>{{ value.product_name }}</td>
                                        <td class="text-center">{{ value.qty }}</td>
                                        <td class="text-center">{{shelter.detail.currency}} {{ value.price.toLocaleString('id-ID') || 0 }}</td>
                                        <td class="text-center">{{shelter.detail.currency}} {{ value.discount.toLocaleString('id-ID') || 0 }}</td>
                                        <td class="text-right">{{shelter.detail.currency}} {{ ((value.price * value.qty) - value.discount).toLocaleString('id-ID') || 0 }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="6" class="bg-light-2 text-right"><strong>Sub Total</strong></td>
                                        <td class="bg-light-2 text-right">{{ shelter.preview.sub_total.toLocaleString('id-ID') }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="6" class="bg-light-2 text-right"><strong>Discount</strong></td>
                                        <td class="bg-light-2 text-right">{{shelter.detail.currency}} {{ shelter.preview.discount != null ? shelter.preview.discount.toLocaleString('id-ID') : 0 }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="6" class="bg-light-2 text-right"><strong>Tax (10%)</strong></td>
                                        <td class="bg-light-2 text-right">{{shelter.detail.currency}} {{ shelter.preview.include_tax == true ? shelter.preview.tax.toLocaleString('id-ID') : '' }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="6" class="bg-light-2 text-right"><strong>Total</strong></td>
                                        <td class="bg-light-2 text-right">{{shelter.detail.currency}} {{ shelter.preview.total.toLocaleString('id-ID') }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="6" class="bg-light-2 text-right"><strong>DP</strong></td>
                                        <td class="bg-light-2 text-right">{{ shelter.preview.down_payment.toLocaleString('id-ID') }}</td>
                                    </tr>
                                    <!-- <tr>
                                        <td colspan="7"><strong>Terbilang:</strong> {{ shelter.preview.total | terbilangJs }}</td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table width="40%" style="line-height: 18px">
                        <thead>
                            <tr>
                                <th colspan="2" class="text-center"> Note </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="2"> - {{ shelter.preview.note.customer_name }} </td>
                            </tr>
                        </tbody>
                    </table>
                </main>
                <br>
                <br>
                <!-- <br> -->
                <footer class="text-center">
                    <p> We thank you for your kind attention and cooperation </p>
                    <div class="row justify-content-md-center">
                        <div class="col-sm-3">
                            &nbsp;
                        </div>
                        <!-- <div class="col-sm-3 text-center">
                            <address>
                                {{ shelter.preview.from.name }}<br />
                                Prepared By<br />
                                <br><br><br><br><br>
                                Nur Anisah<br>
                                Purchasing Staff
                            </address>
                        </div> -->
                        <div class="col-sm-3 text-center">
                            <address>
                                <br>
                                Approved By<br />
                                <br><br><br><br><br>
                                Christina Selvia<br>
                                Purchasing Manager
                            </address>
                        </div>
                        <div class="col-sm-3">
                            &nbsp;
                        </div>
                    </div>

                </footer>
            </div>
        </section>
    </vue-html2pdf>
</div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
// import axios from "axios";

export default {
    name: 'PrintInvoice',
    props: {
        dataPurchaseOrder: {
            type: Object,
            required: true
        }
    },
    watch: {
        dataPurchaseOrder: {
            immediate: true,
            handler() {
                this.shelter = Object.assign({}, this.dataPurchaseOrder);
            }
        }
    },
    data() {
        return {
            shelter: {},
        };
    },
    components: {
        VueHtml2pdf
    },
    filters: {
        terbilangJs: function (value) {
            var terbilang = function (a) {
                var c = " Satu Dua Tiga Empat Lima Enam Tujuh Delapan Sembilan Sepuluh Sebelas".split(" ");
                if (12 > a) var b = c[a];
                else 20 > a ? b = c[a - 10] + " Belas" : 100 > a ? (b = parseInt(String(a / 10).substr(0, 1)), b = c[b] + " Puluh " + c[a % 10]) : 200 > a ? b = "Seratus " + terbilang(a - 100) : 1E3 > a ? (b = parseInt(String(a / 100).substr(0, 1)), b = c[b] + " Ratus " + terbilang(a % 100)) : 2E3 > a ? b = "Seribu " + terbilang(a - 1E3) : 1E4 > a ? (b = parseInt(String(a / 1E3).substr(0, 1)), b = c[b] + " Ribu " + terbilang(a % 1E3)) : 1E5 > a ? (b = parseInt(String(a / 100).substr(0, 2)),
                        a %= 1E3, b = terbilang(b) + " Ribu " + terbilang(a)) : 1E6 > a ? (b = parseInt(String(a / 1E3).substr(0, 3)), a %= 1E3, b = terbilang(b) + " Ribu " + terbilang(a)) : 1E8 > a ? (b = parseInt(String(a / 1E6).substr(0, 4)), a %= 1E6, b = terbilang(b) + " Juta " + terbilang(a)) : 1E9 > a ? (b = parseInt(String(a / 1E6).substr(0, 4)), a %= 1E6, b = terbilang(b) + " Juta " + terbilang(a)) : 1E10 > a ? (b = parseInt(String(a / 1E9).substr(0, 1)), a %= 1E9, b = terbilang(b) + " Milyar " + terbilang(a)) : 1E11 > a ? (b = parseInt(String(a / 1E9).substr(0, 2)), a %= 1E9, b = terbilang(b) + " Milyar " + terbilang(a)) :
                    1E12 > a ? (b = parseInt(String(a / 1E9).substr(0, 3)), a %= 1E9, b = terbilang(b) + " Milyar " + terbilang(a)) : 1E13 > a ? (b = parseInt(String(a / 1E10).substr(0, 1)), a %= 1E10, b = terbilang(b) + " Triliun " + terbilang(a)) : 1E14 > a ? (b = parseInt(String(a / 1E12).substr(0, 2)), a %= 1E12, b = terbilang(b) + " Triliun " + terbilang(a)) : 1E15 > a ? (b = parseInt(String(a / 1E12).substr(0, 3)), a %= 1E12, b = terbilang(b) + " Triliun " + terbilang(a)) : 1E16 > a && (b = parseInt(String(a / 1E15).substr(0, 1)), a %= 1E15, b = terbilang(b) + " Kuadriliun " + terbilang(a));
                a = b.split(" ");
                c = [];
                for (b = 0; b < a.length; b++) "" != a[b] && c.push(a[b]);
                return c.join(" ")
            };
            return terbilang(value) + ' Rupiah';
        }
    },
    computed: {
        filterProduct() {
            let arr = [];

            if (this.shelter.preview.products === undefined) {
                return arr;
            }

            this.shelter.preview.products.forEach(function (value, index) {
                if (index == 0) {
                    arr.push({
                        id_product: value.id_product,
                        part_number: value.part_number,
                        product_name: value.product_name,
                        price: value.price,
                        discount: value.discount,
                        qty: 1,
                    });
                } else {
                    let found = false;

                    for (let i = 0; i < arr.length; i++) {
                        const element = arr[i];
                        if (element.part_number == value.part_number) {
                            found = true;
                            arr[i].qty += 1;
                            arr[i].discount += value.discount;
                            // break;
                        }
                    }

                    if (found == false) {
                        arr.push({
                            id_product: value.id_product,
                            part_number: value.part_number,
                            product_name: value.product_name,
                            price: value.price,
                            discount: value.discount,
                            qty: 1
                        });
                    }
                }
            });
            return arr;
        }
    },
    methods: {
        /*
            Generate Report using refs and calling the
            refs function generatePdf()
        */
        generateReport() {
            this.$refs.html2Pdf.generatePdf()
        },
        convertToRoman(num) {
            var roman = {
                M: 1000,
                CM: 900,
                D: 500,
                CD: 400,
                C: 100,
                XC: 90,
                L: 50,
                XL: 40,
                X: 10,
                IX: 9,
                V: 5,
                IV: 4,
                I: 1
            };
            var str = '';

            for (var i of Object.keys(roman)) {
                var q = Math.floor(num / roman[i]);
                num -= q * roman[i];
                str += i.repeat(q);
            }

            return str;
        }
    }
}
</script>

<style scoped>
.container-fluid {
    background: #e7e9ed;
    color: #535b61;
    font-family: "Poppins", sans-serif;
    font-size: 11px;
    /* line-height: 22px; */
}

form {
    padding: 0;
    margin: 0;
    display: inline;
}

img {
    vertical-align: inherit;
}

a,
a:focus {
    color: #0071cc;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

a:hover,
a:active {
    color: #0c2f55;
    text-decoration: none;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

a:focus,
a:active,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button:focus,
button:active {
    outline: none;
}

p {
    line-height: 1.9;
}

blockquote {
    border-left: 5px solid #eee;
    padding: 10px 20px;
}

iframe {
    border: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #0c2f54;
    font-family: "Poppins", sans-serif;
}

.table {
    color: #535b61;
    line-height: 2px;
}

.table-hover tbody tr:hover {
    background-color: #f6f7f8;
}

/* Border Radius */
.rounded-top-0 {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.rounded-bottom-0 {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.rounded-left-0 {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.rounded-right-0 {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

/* Text Size */
.text-0 {
    font-size: 11px !important;
    font-size: 0.6875rem !important;
}

.text-1 {
    font-size: 12px !important;
    font-size: 0.75rem !important;
}

.text-2 {
    font-size: 14px !important;
    font-size: 0.875rem !important;
}

.text-3 {
    font-size: 16px !important;
    font-size: 1rem !important;
}

.text-4 {
    font-size: 15px !important;
    font-size: 1.125rem !important;
}

.text-5 {
    font-size: 21px !important;
    font-size: 1.3125rem !important;
}

.text-6 {
    font-size: 24px !important;
    font-size: 1.50rem !important;
}

.text-7 {
    font-size: 28px !important;
    font-size: 1.75rem !important;
}

.text-8 {
    font-size: 32px !important;
    font-size: 2rem !important;
}

.text-9 {
    font-size: 36px !important;
    font-size: 2.25rem !important;
}

.text-10 {
    font-size: 40px !important;
    font-size: 2.50rem !important;
}

.text-11 {
    font-size: 44px !important;
    font-size: 2.75rem !important;
}

.text-12 {
    font-size: 48px !important;
    font-size: 3rem !important;
}

.text-13 {
    font-size: 52px !important;
    font-size: 3.25rem !important;
}

.text-14 {
    font-size: 56px !important;
    font-size: 3.50rem !important;
}

.text-15 {
    font-size: 60px !important;
    font-size: 3.75rem !important;
}

.text-16 {
    font-size: 64px !important;
    font-size: 4rem !important;
}

.text-17 {
    font-size: 72px !important;
    font-size: 4.5rem !important;
}

.text-18 {
    font-size: 80px !important;
    font-size: 5rem !important;
}

.text-19 {
    font-size: 84px !important;
    font-size: 5.25rem !important;
}

.text-20 {
    font-size: 92px !important;
    font-size: 5.75rem !important;
}

/* Line height */
.line-height-07 {
    line-height: 0.7 !important;
}

.line-height-1 {
    line-height: 1 !important;
}

.line-height-2 {
    line-height: 1.2 !important;
}

.line-height-3 {
    line-height: 1.4 !important;
}

.line-height-4 {
    line-height: 1.6 !important;
}

.line-height-5 {
    line-height: 1.8 !important;
}

/* Font Weight */
.font-weight-100 {
    font-weight: 100 !important;
}

.font-weight-200 {
    font-weight: 200 !important;
}

.font-weight-300 {
    font-weight: 300 !important;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.font-weight-800 {
    font-weight: 800 !important;
}

.font-weight-900 {
    font-weight: 900 !important;
}

/* Opacity */
.opacity-0 {
    opacity: 0;
}

.opacity-1 {
    opacity: 0.1;
}

.opacity-2 {
    opacity: 0.2;
}

.opacity-3 {
    opacity: 0.3;
}

.opacity-4 {
    opacity: 0.4;
}

.opacity-5 {
    opacity: 0.5;
}

.opacity-6 {
    opacity: 0.6;
}

.opacity-7 {
    opacity: 0.7;
}

.opacity-8 {
    opacity: 0.8;
}

.opacity-9 {
    opacity: 0.9;
}

.opacity-10 {
    opacity: 1;
}

/* Background light */
.bg-light {
    background-color: #FFF !important;
}

.bg-light-1 {
    background-color: #f9f9fb !important;
}

.bg-light-2 {
    background-color: #f8f8fa !important;
}

.bg-light-3 {
    background-color: #f5f5f5 !important;
}

.bg-light-4 {
    background-color: #eff0f2 !important;
}

.bg-light-5 {
    background-color: #ececec !important;
}

@media print {

    .table td,
    .table th {
        background-color: transparent !important;
    }

    .table td.bg-light,
    .table th.bg-light {
        background-color: #FFF !important;
    }

    .table td.bg-light-1,
    .table th.bg-light-1 {
        background-color: #f9f9fb !important;
    }

    .table td.bg-light-2,
    .table th.bg-light-2 {
        background-color: #f8f8fa !important;
    }

    .table td.bg-light-3,
    .table th.bg-light-3 {
        background-color: #f5f5f5 !important;
    }

    .table td.bg-light-4,
    .table th.bg-light-4 {
        background-color: #eff0f2 !important;
    }

    .table td.bg-light-5,
    .table th.bg-light-5 {
        background-color: #ececec !important;
    }
}

/* =================================== */
/*  Layouts
/* =================================== */
.invoice-container {
    margin: 0 auto;
    /* padding-top: 70px; */
    padding: 40px 30px 30px 40px;
    max-width: 850px;
    background-color: #fff;
    /* border: 1px solid #ccc; */
    /* -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    -o-border-radius: 6px; */
    /* border-radius: 6px; */
}

/* =================================== */
/*  Extras
/* =================================== */
.bg-primary,
.badge-primary {
    background-color: #0071cc !important;
}

.bg-secondary {
    background-color: #0c2f55 !important;
}

.text-secondary {
    color: #0c2f55 !important;
}

.text-primary {
    color: #0071cc !important;
}

.btn-link {
    color: #0071cc;
}

.btn-link:hover {
    color: #0e7fd9 !important;
}

.border-primary {
    border-color: #0071cc !important;
}

.border-secondary {
    border-color: #0c2f55 !important;
}

.btn-primary {
    background-color: #0071cc;
    border-color: #0071cc;
}

.btn-primary:hover {
    background-color: #0e7fd9;
    border-color: #0e7fd9;
}

.btn-secondary {
    background-color: #0c2f55;
    border-color: #0c2f55;
}

.btn-outline-primary {
    color: #0071cc;
    border-color: #0071cc;
}

.btn-outline-primary:hover {
    background-color: #0071cc;
    border-color: #0071cc;
    color: #fff;
}

.btn-outline-secondary {
    color: #0c2f55;
    border-color: #0c2f55;
}

.btn-outline-secondary:hover {
    background-color: #0c2f55;
    border-color: #0c2f55;
    color: #fff;
}

.progress-bar,
.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #0071cc;
}

.page-item.active .page-link,
.custom-radio .custom-control-input:checked~.custom-control-label:before,
.custom-control-input:checked~.custom-control-label::before,
.custom-checkbox .custom-control-input:checked~.custom-control-label:before,
.custom-control-input:checked~.custom-control-label:before {
    background-color: #0071cc;
    border-color: #0071cc;
}

.list-group-item.active {
    background-color: #0071cc;
    border-color: #0071cc;
}

.page-link {
    color: #0071cc;
}

.page-link:hover {
    color: #0e7fd9;
}

/* Pagination */
.page-link {
    border-color: #f4f4f4;
    border-radius: 0.25rem;
    margin: 0 0.3rem;
}

.page-item.disabled .page-link {
    border-color: #f4f4f4;
}

table,
th,
td {
    border: 1px solid black;
}

table {
    border-bottom: 0;
    border-left: 0;
}

td,
th {
    border-top: 0;
    border-right: 0;
}

.table tbody tr td {
    border-color: black !important
}

.table thead tr th {
    border-color: black !important
}
</style>
